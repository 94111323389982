body {
    padding: 0px;
    margin: 0px;
    font-family: "FreeSans";
}

* {
    font-family: "FreeSans" !important;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: var(--font-secondary);   
    src: local("autocircle"), url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Poppins:wght@300;400;500;600;700;800;900&display=swap') format("truetype");
}

/* Light font has a problem */
/* @font-face {
    font-family: 'HelveticaLight';
    src: local('HelveticaLight'), url('../fonts/Helvetica/Helvetica-Light.ttf') format('truetype')
} */

@font-face {
    font-family: "FreeSansOblique";
    src: local("FreeSansOblique"), url("../fonts/FreeSans/FreeSansOblique.ttf") format("truetype");
}

@font-face {
    font-family: "FreeSansBold";
    src: local("FreeSansBold"), url("../fonts/FreeSans/FreeSansBold.ttf") format("truetype");
}

@font-face {
    font-family: "FreeSansBoldOblique";
    src: local("FreeSansBoldOblique"), url("../fonts/FreeSans/FreeSansBoldOblique.ttf") format("truetype");
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

a {
    font-size: 16px;
    text-decoration: none;
    color: #0070f4;
    font-family: "FreeSans";
}
a:hover {
    color: #485474;
}

button {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: 0px;
    font-family: "FreeSans";
    cursor: pointer;
}

h3 {
    font-family: "FreeSansBold";
    font-size: 24px;
    color: #191919;
    font-weight: bold;
    line-height: 1.1;
}

/* width */
::-webkit-scrollbar {
    width: auto;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0070f4;
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0070f4;
}

/* Container */

/* Header */
/* header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0px 24px;
    margin-bottom:24px;
} */

/* header nav {
    display: block;
}

header nav a {
    margin: 0px 20px;
    padding: 4px;
    color: var(--black);
    text-decoration: none;
}

header nav a.active {
    border-bottom: 2px solid var(--aquablue);
}

.auth button {
    padding: 12px 30px;
    margin: 0px 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
}

button.blue-outlined {
    border: 2px solid var(--blue);
    color: var(--blue);
    font-weight: bold;
    background-color: var(--white);
}

button.blue {
    background-color: var(--blue);
    color: var(--white); 
} */

/* Footer */
/* footer {
    height: 294px;
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 50px;
    box-sizing: border-box;
    
}

footer section {
    min-width: 300px;
}

footer h3 {
    color: var(--white);
    margin: 0px 0px 26px;
}

footer nav a, footer .working-hours div {
    margin: 8px 0px;
    padding: 4px 0px;
}

footer nav a {
    display: block;
    color: var(--white);
    text-decoration: none;
}

footer nav .working-hours {
    display: block;
}
footer nav .working-hours div {
    display: flex;
    justify-content: space-between;
} */

/* Home */
/* .home .slider {
    background-image: url('../svg/home-slider-bg.svg');
    background-repeat: no-repeat;
    background-size: 1290px 500px;
    background-position: right;
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.home .slider h1 {
    font-size: 36px;
    color: var(--blue);
}

.home .partners {
    height: 430px;
    text-align: center;
} */

/* Variables */
/* :root {
    --black: black;
    --blue: #193A8D;
    --aquablue: #0070f4;
    --white: #ffffff;
  } */
